import React, { useContext } from "react";
import { Anchor, Box, Main, Text, ResponsiveContext } from "grommet";
import styled from "styled-components";
import SizingHelper from '../helpers/sizing';
import PageHeading from "./PageHeading";

const StyledContactLabel = styled(Text)`
  font-family: KansasNew;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
`;

const StyledAnchorDetail = styled(Anchor)`
  font-family: KansasNew;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
`;

const StyledAnchorDetailSmall = styled(Anchor)`
  font-family: KansasNew;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

const ContactUs = () => {
  const size = useContext(ResponsiveContext);
  const displayHorizontal = !SizingHelper.screenIsMobile(size);

  return (
    <Main fill="vertical">
      <PageHeading
        text="Contact Us"
      />
      {
        displayHorizontal ? (
          <Box
            fill="horizontal"
            direction="row"
            justify="center"
            gap="120px"
            pad={{ top: '80px', bottom : '320px' }}
          >
            <Box align="end" fill>
              <Box gap="24px" width={{ max: '360px' }}>
                <StyledContactLabel color="forest">Call/WhatsApp us</StyledContactLabel>
                <StyledAnchorDetail href="tel:65-9058-5214" color="siren" label="+65 9058-5214" />
              </Box>
            </Box>
            <Box fill>
              <Box gap="24px" width={{ max: '360px' }}>
                <StyledContactLabel color="forest">Email us</StyledContactLabel>
                <StyledAnchorDetail href="mailto:contact@heymerry.com.sg" color="siren" label="contact@heymerry.com.sg" />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            fill="horizontal"
            gap="120px"
            pad={{ top: '80px', bottom : '320px', horizontal: 'large' }}
          >
            <Box gap="24px" align="center">
              <StyledContactLabel color="forest">Call/WhatsApp us</StyledContactLabel>
              <StyledAnchorDetailSmall href="tel:65-9058-5214" color="siren" label="+65 9058-5214" size="10px" />
            </Box>
            <Box gap="24px" align="center">
              <StyledContactLabel color="forest">Email us</StyledContactLabel>
              <StyledAnchorDetailSmall href="mailto:contact@heymerry.com.sg" color="siren" label="contact@heymerry.com.sg" />
            </Box>
          </Box>
        )
      }
    </Main>
  );
};

export default ContactUs;
