import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { inject } from "mobx-react";
import { observer } from "mobx-react";
import { Anchor, Box, Grid, Image, Main, ResponsiveContext, Text, Paragraph } from "grommet";
import styled from 'styled-components';
import ReactCountryFlag from "react-country-flag";
import LocationMarker from "images/location_marker.png";
import SkillHousehold from "images/skill_household.png";
import SkillCooking from "images/skill_cooking.png";
import SkillChildcare from "images/skill_childcare.png";
import SkillElderly from "images/skill_elderly.png";
import WorkExperienceAdultIcon from "images/work_experience_adult_icon.png";
import WorkExperienceChildIcon from "images/work_experience_child_icon.png";
import WorkExperienceElderlyIcon from "images/work_experience_elderly_icon.png";
import WorkExperiencePetIcon from "images/work_experience_pet_icon.png";
import SizingHelper from '../helpers/sizing';
import CountryFlagHelper from '../helpers/countryFlag';
import HelperAvailability from "./HelperAvailability";

const StyledBackBox = styled(Box)`
  display: inline-block;
`;

const StyledBackAnchor = styled(Anchor)`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  font-family: freight-sans-pro;
`;

const StyledDescriptionHeading = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

const StyledDescriptionText = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  font-family: freight-sans-pro;
`;

const StyledMessageParagraph = styled(Paragraph)`
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  font-family: freight-sans-pro;
  white-space: pre-line;
`;

const StyledHelperName = styled(Text)`
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  font-family: KansasNew;
`;

const StyledCategoryName = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  font-family: KansasNew;

`;

const StyledSkillName = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-family: KansasNew;
`;

const StyledSkillText = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

const StyledTableHeading = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: KansasNew;
`;

const StyledTableText = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

const StyledTableTextHeader = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  font-family: freight-sans-pro;
`;

const StyledBackgroundBox = styled(Box)`
  position: absolute;
  top: 120;
  left: 0;
  z-index: -1;
`;

const HelperProfile = inject('store')(observer(({ store, ...props }) => {
  const location = useLocation();
  const{ id } = location.state;

  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const horizontalPadding = SizingHelper.horizontalPadding(size);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  const {
    name,
    age,
    availableOn,
    currentSalary,
    workExperience,
    daysOffPerMonth,
    desiredPublicHolidayOff,
    dietaryRestrictions,
    expectedSalary,
    family,
    hireType,
    languages,
    location: helperLocation,
    maritalStatus,
    message,
    nationality,
    profileImage,
    religion,
    requests,
    skills,
    sleepingArrangement,
    yearsOfExperience,
  } = store.findHelperById(id);

  return (
    <Main fill="vertical">
      <StyledBackBox pad={{ top: '40px', horizontal: horizontalPadding }}>
        <StyledBackAnchor
          color="text"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back to all candidates
        </StyledBackAnchor>
      </StyledBackBox>
      <Box
        direction={ screenIsMobile ? 'column' : 'row' }
        pad={{ vertical: '20px', horizontal: horizontalPadding }}
      >
        <Box
          width={ screenIsMobile ? null : { min: '352px', max: '352px' }}
          pad={ screenIsMobile ? null : { right: '36px' }}
        >
          <Box
            pad={{ top: '20px', bottom: '40px' }}
            gap="40px"
            align={ screenIsMobile ? 'center' : 'start' }
            fill="horizontal"
          >
            <Box background="apricot" height="280px" width="280px" round="full" overflow="hidden">
              { profileImage && <Image fit="cover" src={ profileImage } /> }
            </Box>
            {
              !screenIsMobile && (
                <Box gap="8px">
                  <StyledMessageParagraph color="forest" margin="none">{message}</StyledMessageParagraph>
                </Box>
                )
            }
          </Box>
        </Box>
        <Box pad={ screenIsMobile ? (null) : ({ left: '36px' })} gap="16px" fill="horizontal">
          <StyledHelperName color="forest" textAlign={ screenIsMobile ? 'center' : null }>{name}</StyledHelperName>
          <Box align={ screenIsMobile ? 'center' : 'start' }>
            <HelperAvailability type={hireType} date={availableOn} />
          </Box>
          <Box
            direction="row"
            gap="4px"
            justify={ screenIsMobile ? 'center' : 'start' }
          >
            <Box
              width="24px"
              height="24px"
              overflow="hidden"
              round="full"
              justify="center"
              align="center"
            >
              <ReactCountryFlag
                countryCode={CountryFlagHelper.getCodeForNationality(nationality)}
                svg
                style={{
                  width: '36px',
                  height: '36px',
                }}
                title={nationality}
                round="full"
              />
            </Box>
            <StyledDescriptionText color="text">{nationality}</StyledDescriptionText>
            <Image height="24px" width="24px" src={LocationMarker} />
            <StyledDescriptionText color="text">{helperLocation}</StyledDescriptionText>
          </Box>
          <Box gap="20px" direction={ screenIsMobile ? 'column' : 'row' }>
            <StyledDescriptionText color="text" textAlign={ screenIsMobile ? 'center' : null }>{age} years old</StyledDescriptionText>
            <StyledDescriptionText color="text" textAlign={ screenIsMobile ? 'center' : null }>
              {maritalStatus}
              {
                family && maritalStatus ? "; " : null
              }
              {family}
            </StyledDescriptionText>
          </Box>
          <Box>
            <StyledDescriptionText color="text" textAlign={ screenIsMobile ? 'center' : null }>{religion}</StyledDescriptionText>
          </Box>
          {
            screenIsMobile && (
              <Box gap="8px" margin={{ top: '20px' }}>
                <StyledMessageParagraph color="forest" margin="none">{message}</StyledMessageParagraph>
              </Box>
            )
          }
          <Box margin={{ top: '24px' }} gap="40px">
            <Grid
              fill="horizontal"
              columns={{ count: 'fit', size: 'xsmall' }}
              gap={{ column: '36px', row: '16px' }}
            >
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Current Salary</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">${currentSalary}</StyledDescriptionText>
              </Box>
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Expected Salary</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">${expectedSalary}</StyledDescriptionText>
              </Box>
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Experience</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{yearsOfExperience}</StyledDescriptionText>
              </Box>
            </Grid>
            <Grid
              fill="horizontal"
              columns={{ count: 'fit', size: 'xsmall' }}
              gap={{ column: '72px', row: '16px' }}
            >
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Expected days off per month</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{daysOffPerMonth} days</StyledDescriptionText>
              </Box>
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Desired public holiday off</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{desiredPublicHolidayOff}</StyledDescriptionText>
              </Box>
            </Grid>
            <Grid
              fill="horizontal"
              columns={{ count: 'fit', size: 'xsmall' }}
              gap={{ column: '72px', row: '16px' }}
            >
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Languages</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{languages}</StyledDescriptionText>
              </Box>
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Dietary restrictions</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{dietaryRestrictions}</StyledDescriptionText>
              </Box>
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Required sleeping arrangement</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{sleepingArrangement}</StyledDescriptionText>
              </Box>
            </Grid>
            <Grid
              fill="horizontal"
              columns={{ count: 'fit', size: 'xsmall' }}
              gap={{ column: '72px', row: '16px' }}
            >
              <Box gap="8px">
                <StyledDescriptionHeading color="text">Requests</StyledDescriptionHeading>
                <StyledDescriptionText color="forest">{requests}</StyledDescriptionText>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box gap="24px" pad={{ top: '40px', bottom: '20px', horizontal: horizontalPadding }}>
        <StyledCategoryName color="forest">Skills</StyledCategoryName>
        <Grid
          fill="horizontal"
          columns={{ count: 'fit', size: 'small' }}
          gap="32px"
        >
          <Box pad="24px" background="cream" round="10px" basis="1/4">
            <Box direction="row" gap="32px">
              <Box gap="8px" fill="horizontalV">
                <StyledSkillName color="forest">Household</StyledSkillName>
                <Box gap="4px">
                  {
                    skills.get('household') && (
                      skills.get('household').skills.map(( value ) => {
                        return <StyledSkillText color="text" key={`skill-${value}`}>{value}</StyledSkillText>
                      })
                    )
                  }
                </Box>
              </Box>
              <Box>
                <Image height="32px" width="32px" src={SkillHousehold} />
              </Box>
            </Box>
          </Box>
          <Box pad="24px" background="cream" round="10px">
            <Box direction="row" gap="32px">
              <Box gap="8px" fill="horizontalV">
                <StyledSkillName color="forest">Cooking</StyledSkillName>
                <Box gap="4px">
                  {
                    skills.get('cooking') && (
                      skills.get('cooking').skills.map(( value ) => {
                        return <StyledSkillText color="text" key={`skill-${value}`}>{value}</StyledSkillText>
                      })
                    )
                  }
                </Box>
              </Box>
              <Box>
                <Image height="32px" width="32px" src={SkillCooking} />
              </Box>
            </Box>
          </Box>
          <Box pad="24px" background="cream" round="10px">
            <Box direction="row" gap="32px">
              <Box gap="8px" fill="horizontalV">
                <StyledSkillName color="forest">Childcare</StyledSkillName>
                <Box gap="4px">
                  {
                    skills.get('childcare') && (
                      skills.get('childcare').skills.map(( value ) => {
                        return <StyledSkillText color="text" key={`skill-${value}`}>{value}</StyledSkillText>
                      })
                    )
                  }
                </Box>
              </Box>
              <Box>
                <Image height="32px" width="32px" src={SkillChildcare} />
              </Box>
            </Box>
          </Box>
          <Box pad="24px" background="cream" round="10px">
            <Box direction="row" gap="32px">
              <Box gap="8px" fill="horizontalV">
                <StyledSkillName color="forest">Elderly</StyledSkillName>
                <Box gap="4px">
                  {
                    skills.get('elderly') && (
                      skills.get('elderly').skills.map(( value ) => {
                        return <StyledSkillText color="text" key={`skill-${value}`}>{value}</StyledSkillText>
                      })
                    )
                  }
                </Box>
              </Box>
              <Box>
                <Image height="32px" width="32px" src={SkillElderly} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box gap="24px" pad={{ top: '40px', bottom: '80px', horizontal: horizontalPadding }}>
        <StyledCategoryName color="forest">Work Experience</StyledCategoryName>
        {
          screenIsMobile ? (
            <Box gap="32px">
              {
                workExperience.map(({ period, duration, employer, skills = '', comments }) => {
                  return (
                    <Box>
                      <Box
                        background="text"
                        pad={{ horizontal: "24px", vertical: "16px" }}
                        round={{ size: '8px', corner: "top" }}
                        gap="16px"
                      >
                        <StyledTableHeading color="sage">{period}</StyledTableHeading>
                        <StyledTableHeading color="sage">{duration}</StyledTableHeading>
                      </Box>
                      <Box border={{ color: 'sage', size: '1px' }}>
                        <Box
                          gap="8px"
                          pad={{ horizontal: '24px', vertical: '24px' }}
                        >
                          <StyledTableText color="forest">{employer?.nationality}</StyledTableText>
                          <Box gap="4px">
                            {
                              employer?.adults && (
                                <Box gap="4px" direction="row">
                                  <Image height="24px" width="24px" src={WorkExperienceAdultIcon} />
                                  <StyledTableText color="forest">{employer?.adults}</StyledTableText>
                                </Box>
                              )
                            }
                            {
                              employer?.children && (
                                <Box gap="4px" direction="row">
                                  <Image height="24px" width="24px" src={WorkExperienceChildIcon} />
                                  <StyledTableText color="forest">{employer?.children}</StyledTableText>
                                </Box>
                              )
                            }
                            {
                              employer?.elderly && (
                                <Box gap="4px" direction="row">
                                  <Image height="24px" width="24px" src={WorkExperienceElderlyIcon} />
                                  <StyledTableText color="forest">{employer?.elderly}</StyledTableText>
                                </Box>
                              )
                            }
                            {
                              employer?.pets && (
                                <Box gap="4px" direction="row">
                                  <Image height="24px" width="24px" src={WorkExperiencePetIcon} />
                                  <StyledTableText color="forest">{employer?.pets}</StyledTableText>
                                </Box>
                              )
                            }
                          </Box>
                        </Box>
                        <Box
                          gap="16px"
                          background="sage"
                          pad={{ horizontal: '24px', vertical: '24px' }}
                        >
                          <Box gap="16px" direction="row">
                            {
                              skills.includes('Household') && (
                                <Image height="32px" width="32px" src={SkillHousehold} />
                              )
                            }
                            {
                              skills.includes('Cooking') && (
                                <Image height="32px" width="32px" src={SkillCooking} />
                              )
                            }
                            {
                              skills.includes('Childcare') && (
                                <Image height="32px" width="32px" src={SkillChildcare} />
                              )
                            }
                            {
                              skills.includes('Elderly') && (
                                <Image height="32px" width="32px" src={SkillElderly} />
                              )
                            }
                          </Box>
                          <StyledTableText color="forest">{skills}</StyledTableText>
                        </Box>
                        {
                          comments && (
                            <Box
                              gap="16px"
                              pad={{ horizontal: '24px', vertical: '24px' }}
                            >
                              <StyledTableText color="forest">{comments}</StyledTableText>
                            </Box>
                          )
                        }
                      </Box>
                    </Box>
                  );
                })
              }
            </Box>
          ) : (
            <Box>
              <Box
                background="text"
                direction="row"
                pad={{ horizontal: "24px", vertical: "16px" }}
                round={{ size: '8px', corner: "top" }}
                gap="32px"
              >
                <Box basis="1/4">
                  <StyledTableHeading color="sage">Employment Period</StyledTableHeading>
                </Box>
                <Box basis="1/4">
                  <StyledTableHeading color="sage">Employer Family</StyledTableHeading>
                </Box>
                <Box basis="1/4">
                  <StyledTableHeading color="sage">Job Scope</StyledTableHeading>
                </Box>
                <Box basis="1/4">
                  <StyledTableHeading color="sage">Additional Comments</StyledTableHeading>
                </Box>
              </Box>
              {
                workExperience.map(({ period, duration, employer, skills = '', comments }, index) => {
                  const backgroundColor = index % 2 ? 'sage' : null;

                  return (
                    <Box
                      background={backgroundColor}
                      direction="row"
                      pad={{ horizontal: '24px', vertical: '24px' }}
                      height={{ min: '186px' }}
                      gap="32px"
                    >
                      <Box basis="1/4" gap="8px">
                        <StyledTableTextHeader color="forest">{period}</StyledTableTextHeader>
                        <StyledTableText color="forest">{duration}</StyledTableText>
                      </Box>
                      <Box basis="1/4" gap="8px">
                        <StyledTableText color="forest">{employer?.nationality}</StyledTableText>
                        <Box gap="4px">
                          {
                            employer?.adults && (
                              <Box gap="4px" direction="row">
                                <Image height="24px" width="24px" src={WorkExperienceAdultIcon} />
                                <StyledTableText color="forest">{employer?.adults}</StyledTableText>
                              </Box>
                            )
                          }
                          {
                            employer?.children && (
                              <Box gap="4px" direction="row">
                                <Image height="24px" width="24px" src={WorkExperienceChildIcon} />
                                <StyledTableText color="forest">{employer?.children}</StyledTableText>
                              </Box>
                            )
                          }
                          {
                            employer?.elderly && (
                              <Box gap="4px" direction="row">
                                <Image height="24px" width="24px" src={WorkExperienceElderlyIcon} />
                                <StyledTableText color="forest">{employer?.elderly}</StyledTableText>
                              </Box>
                            )
                          }
                          {
                            employer?.pets && (
                              <Box gap="4px" direction="row">
                                <Image height="24px" width="24px" src={WorkExperiencePetIcon} />
                                <StyledTableText color="forest">{employer?.pets}</StyledTableText>
                              </Box>
                            )
                          }
                        </Box>
                      </Box>
                      <Box basis="1/4" gap="16px">
                        <Box gap="16px" direction="row">
                          {
                            skills.includes('Household') && (
                              <Image height="32px" width="32px" src={SkillHousehold} />
                            )
                          }
                          {
                            skills.includes('Cooking') && (
                              <Image height="32px" width="32px" src={SkillCooking} />
                            )
                          }
                          {
                            skills.includes('Childcare') && (
                              <Image height="32px" width="32px" src={SkillChildcare} />
                            )
                          }
                          {
                            skills.includes('Elderly') && (
                              <Image height="32px" width="32px" src={SkillElderly} />
                            )
                          }
                        </Box>
                        <StyledTableText color="forest">{skills}</StyledTableText>
                      </Box>
                      <Box basis="1/4" gap="16px">
                        <StyledTableText color="forest">{comments}</StyledTableText>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
          )
        }
      </Box>
      <StyledBackgroundBox
        background="cream"
        height="328px"
        fill="horizontal"
      />
    </Main>
  );
}));

export default HelperProfile;
