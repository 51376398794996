const horizontalPadding = (size) => {
  if (size === 'medium' || size === 'large' || size === 'xlarge') {
    return '240px';
  }

  return 'large';
}

const screenIsMobile = (size) => {
  return size !== 'medium' && size !== 'large' && size !== 'xlarge';
}

const sizing = Object.freeze({
  horizontalPadding,
  screenIsMobile,
});

export default sizing;
