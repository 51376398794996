import { types } from 'mobx-state-tree';
import { Helper } from '../helper-store/helper-store';

export const RootStore = types
  .model('RootStore', {
    helpers: types.maybe(types.map(Helper), null),
  })
  .views((self) => ({
    findHelperById(id) {
      if (!self.helpers) {
        self.helpers = {};
      }

      const helper = self.helpers.get(id)
      console.log(helper)

      return helper;
    },
  }))
  .actions((self) => ({
    getRecommendations: async (endpoint) => {
      try {
        const response = await fetch(`/api/v1/candidate_web_links/${endpoint}`);
        const result = await response.json();

        if (!result.error) {
          result.data.forEach(helperData => (self.addHelper(helperData)))
        }

      } catch (error) {
        console.log(error);
      }
    },
    addHelper(data) {
      if (!self.helpers) {
        self.helpers = {};
      }

      const {
        id,
        age,
        nationality,
        location,
        first_name: firstName,
        last_name: lastName,
        marital_status: maritalStatus,
        hire_type: hireType,
        available_on: availableOn,
        profile_image: profileImage,
        current_salary: currentSalary,
        day_offs: daysOffPerMonth,
        dietary_restrictions: dietaryRestrictions,
        expected_salary: expectedSalary,
        religion,
        introduction,
        family,
        skills,
        language: languages,
        years_experience: yearsOfExperience,
        work_experience: workExperience,
        requests,
        sleeping_arrangement: sleepingArrangement,
        desired_public_holiday_off: desiredPublicHolidayOff,
      } = data;

      const helperData = {
        nationality,
        location,
        age,
        maritalStatus,
        hireType,
        availableOn,
        profileImage,
        languages,
        family,
        yearsOfExperience: yearsOfExperience.toString(),
        currentSalary: currentSalary.toString(),
        daysOffPerMonth: daysOffPerMonth.toString(),
        dietaryRestrictions,
        expectedSalary: expectedSalary.toString(),
        religion,
        skills,
        id: id.toString(),
        name: `${firstName} ${lastName}`,
        message: introduction,
        workExperience,
        requests,
        desiredPublicHolidayOff,
        sleepingArrangement,
      }

      for (const key in helperData.skills) {
        helperData.skills[key].categoryName = helperData.skills[key].category_name
        delete helperData.skills[key].category_name
      }

      for (const work in helperData.workExperience) {
        helperData.workExperience[work].housingType = helperData.workExperience[work].housing_type
        delete helperData.workExperience[work].housing_type

        helperData.workExperience[work].reasonForLeaving = helperData.workExperience[work].reason_for_leaving
        delete helperData.workExperience[work].reason_for_leaving
      }

      self.helpers.set(id, Helper.create(helperData));
    },
  }));
