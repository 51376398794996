import React from "react";
import { Box, Heading } from "grommet";
import styled from 'styled-components';

const StyledHeading = styled(Heading)`
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  font-family: KansasNew;
`;

const StyledSubHeading = styled(Heading)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: KansasNew;
`;

const SectionHeading = ({ text, subText, background }) => {
  return (
    <Box
      justify="center"
      fill="horizontal"
      align="center"
      pad={{ top: '80px', bottom: '40px', horizontal: 'large' }}
      gap="16px"
      background={background}
    >
      <StyledHeading color="siren" margin="none" textAlign="center">{text}</StyledHeading>
      { subText &&
        <StyledSubHeading color="forest" margin="none" textAlign="center">{subText}</StyledSubHeading>
      }
    </Box>
  );
};

export default SectionHeading;
