import React from "react";
import { Button as GButton, Text } from "grommet";
import styled from 'styled-components';

export const StyledText = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-family: KansasNew;
  margin: 0px 10px;
`;

export const StyledButton = styled(GButton)`
  padding: 16px;
  border-radius: 40px;
  min-width: 240px;
  text-align: center;
`;

const Button = ({ label, ...props }) => {
  return (
    <StyledButton
      primary
      color="siren"
      pad="16px"
      label={
        <StyledText color="cream">{label}</StyledText>
      }
      {...props}
    />
  );
};

export default Button;
