import React, { useContext } from "react";
import { Box, Image, Grid, Main, Paragraph as GParagraph, Text, ResponsiveContext } from "grommet";
import styled from "styled-components";
import UnitedNations from "images/united_nations.png"
import Goal8 from "images/goal8.png";
import Goal10 from "images/goal10.png";
import HelperCount from "images/helper_count.png";
import HelperTestimony from "images/helper_testimony.png";
import HelperSalary from "images/helper_salary.png";
import SizingHelper from '../helpers/sizing';
import PageHeading from "./PageHeading";
import SectionHeading from "./SectionHeading";

const StyledParagraph = styled(GParagraph)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
`;

const StyledParagraphHeading = styled(GParagraph)`
  font-family: KansasNew;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const StyledHeadingText = styled(Text)`
  font-family: KansasNew;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

const StyledPointText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
`;

const Paragraph = ({...props}) => (<StyledParagraph fill color="forest" margin="none" {...props} />)
const ParagraphHeading = ({...props}) => (<StyledParagraphHeading fill color="forest" margin="none" {...props} />)

const Sustainability = () => {
  const size = useContext(ResponsiveContext);
  const horizontalPadding = SizingHelper.horizontalPadding(size);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  return (
    <Main fill="vertical">
      <PageHeading text="Sustainability" subText="We are committed to the United Nations Sustainable Development Goals"/>
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'small' }}
        pad={{ horizontal: horizontalPadding, vertical: '80px' }}
        gap="26px"
        align="start"
      >
        <Box>
          <Image fill="horizontal" fit={ screenIsMobile ? "cover" : "contain" } src={UnitedNations} />
        </Box>
        <Box gap="24px">
          <Paragraph>We strive to contribute to the social aspect of UN SDGs by assisting migrant domestic helpers to find good jobs where they get a pleasant working environment and higher salaries as they upgrade their skills and knowledge.</Paragraph>
          <Paragraph>Migrant workers should not be charged any form of placement fee, even if they are being hired from their home country, and so we want to help them for free.</Paragraph>
        </Box>
      </Grid>
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'medium' }}
        pad={{ horizontal: horizontalPadding, bottom: '80px' }}
        gap="34px"
      >
        <Grid
          fill="horizontal"
          columns={{ count: 'fit', size: 'small' }}
          gap="34px"
          align="start"
          alignContent="start"
        >
          <Image fill="horizontal" fit="contain" src={Goal8} alignSelf="start" />
          <Box fill="vertical" gap="24px" align="start" justify="start">
            <ParagraphHeading>Everyone deserves "decent work", and we place helpers in pleasant working environment</ParagraphHeading>
            <Paragraph><b>Goal #8.8</b> – Protect labour rights and promote safe and secure working environments for all workers, including migrant workers, in particular women migrants, and those in precarious employment</Paragraph>
          </Box>
        </Grid>
        <Grid
          fill="horizontal"
          columns={{ count: 'fit', size: 'medium' }}
          gap="34px"
          align="start"
          alignContent="start"
        >
          <Grid
            fill="horizontal"
            columns={{ count: 'fit', size: 'small' }}
            gap="34px"
            align="start"
            alignContent="start"
          >
            <Image fill="horizontal" fit="contain" src={Goal10} alignSelf="start" />
            <Box fill="vertical" gap="24px" align="start" justify="start">
              <ParagraphHeading>Everyone deserves to be paid fairly, and we assist helpers to get higher salaries as their skills improve</ParagraphHeading>
              <Paragraph><b>Goal #10.1</b> – By 2030, progressively achieve and sustain income growth of the bottom 40% of the population at a rate higher than the national average</Paragraph>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <SectionHeading
        background="cream"
        text="Keeping ourselves accountable"
        subText="We will report at the end of every quarter:"
      />
      <Box
        background="cream"
        pad={{ top: '24px', bottom: '80px', horizontal: 'large' }}
        gap="40px"
        align="center"
      >
        <Box direction="row" gap="30px">
          <Image height="64px" width="64px" src={HelperCount} />
          <Box gap="4px" width={{ max: '490px' }}>
            <StyledHeadingText>Total no. of helpers</StyledHeadingText>
            <StyledPointText>whom we have placed, and a statement to attest that the minimum salary is achieved</StyledPointText>
          </Box>
        </Box>
        <Box direction="row" gap="30px">
          <Image height="64px" width="64px" src={HelperTestimony} />
          <Box gap="4px" width={{ max: '490px' }}>
            <StyledHeadingText>A personal account</StyledHeadingText>
            <StyledPointText>from every helper that she is satisfied and finds the current working environment pleasant</StyledPointText>
          </Box>
        </Box>
        <Box direction="row" gap="30px">
          <Image height="64px" width="64px" src={HelperSalary} />
          <Box gap="4px" width={{ max: '490px' }}>
            <StyledHeadingText>Their salary % increase</StyledHeadingText>
            <StyledPointText>The average salaries obtained and the % increment from their last salary drawn</StyledPointText>
          </Box>
        </Box>
      </Box>
    </Main>
  );
};

export default Sustainability;
