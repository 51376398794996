import React, { useContext } from "react";
import { Box, Heading, ResponsiveContext } from "grommet";
import styled from 'styled-components';
import SizingHelper from '../helpers/sizing';

export const StyledHeading = styled(Heading)`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  font-family: KansasNew;
`;

export const StyledSubHeading = styled(Heading)`
  font-size: 58px;
  font-weight: 400;
  line-height: 64px;
  font-family: KansasNew;
`;

export const StyledSubHeadingSmall = styled(Heading)`
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  font-family: KansasNew;
`;

const PageHeading = ({ text, subText, textChild, subTextChild }) => {
  const size = useContext(ResponsiveContext);
  const displayLarge = !SizingHelper.screenIsMobile(size);;

  return (
    <Box justify="center" fill="horizontal" background="sage" align="center" pad={ displayLarge ? "80px" : "24px" } gap="32px">
      { textChild }
      <StyledHeading color="forest" margin="none" textAlign="center">{text}</StyledHeading>
      { subTextChild }
      { subText && displayLarge &&
        <StyledSubHeading color="forest" margin="none" textAlign="center">{subText}</StyledSubHeading>
      }
      { subText && !displayLarge &&
        <StyledSubHeadingSmall color="forest" margin="none" textAlign="center">{subText}</StyledSubHeadingSmall>
      }
    </Box>
  );
};

export default PageHeading;
