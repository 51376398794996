import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Anchor, Box, Footer as GFooter, Text, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import SizingHelper from '../helpers/sizing';

const StyledAnchor = styled(Anchor)`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

const StyledText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

const StyledBar = styled(Box)`
  height: 1px;
  flex: 1;
  background-color: #FAF7F0;
  opacity: 0.2;
`;

const FooterAnchor = ({ label, route }) => {
  const navigate = useNavigate();

  return <StyledAnchor color="cream" onClick={() => { navigate(route) }}>{label}</StyledAnchor>;
}

const Footer = () => {
  const size = useContext(ResponsiveContext);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  return (
    <>
      <GFooter
        background="forest"
        pad={{
          vertical: '80px',
          horizontal: 'xlarge',
        }}
        align="start"
        justify="start"
      >
        <Box gap="24px" justify="start" align="start" alignContent="start">
          <Box gap="16px">
            <FooterAnchor label="Home" route="/" />
            <FooterAnchor label="About Us" route="/about-us" />
            <FooterAnchor label="Helpers" route="/helpers" />
            <FooterAnchor label="Families" route="/families" />
            <FooterAnchor label="Sustainability" route="/sustainability" />
          </Box>
        </Box>
        <Box gap="24px">
          <Box gap="16px">
            <FooterAnchor label="FAQ" route="/faq" />
            <FooterAnchor label="Contact Us" route="/contact-us" />
          </Box>
        </Box>
      </GFooter>
      <GFooter background="forest" pad={{ horizontal: 'xlarge', bottom: '15px' }}>
        <StyledBar />
      </GFooter>
      <GFooter
        background="forest"
        pad={{
          horizontal: 'xlarge',
          bottom: '40px',
        }}
        direction={ screenIsMobile ? "column" : "row" }
        align="start"
      >
        <StyledText alignSelf="center" color="cream">
          Hey Merry Pte. Ltd. is an employment agency licensed by the Ministry of Manpower (License No. 21c0702)
        </StyledText>
        <StyledText color="cream">
          ©Copyright
        </StyledText>
      </GFooter>
    </>
  );
}

export default Footer;
