import React, { useContext } from "react";
import { Anchor, Accordion, AccordionPanel, Box, Heading, Main, Paragraph, ResponsiveContext } from "grommet";
import styled from 'styled-components';
import SizingHelper from '../helpers/sizing';
import PageHeading from "./PageHeading";
import SectionHeading from "./SectionHeading";

const StyledAccordionHeading = styled(Heading)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: KansasNew;
`;

const StyledAccordionPanel = styled(AccordionPanel)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid sage;
`;

const StyledAccordionBox = styled(Box)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: freight-sans-pro;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 23px;
`;

const StyledParagraph = styled(Paragraph)`
  font-family: freight-sans-pro;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 23px;
`;

const AccordionSection = ({ label, children }) => (
  <StyledAccordionPanel
    background="greengrey"
    pad={{ vertical: '24px', left: '24px', right: '8px' }}
    label={<StyledAccordionHeading fill margin="none">{label}</StyledAccordionHeading>}
  >
    <StyledAccordionBox pad="24px" background="white" gap="24px">
      {children}
    </StyledAccordionBox>
  </StyledAccordionPanel>
);

const AccordionParagraph = ({ children }) => (
  <StyledParagraph margin="none " fill>{children}</StyledParagraph>
);

const Faq = () => {
  const size = useContext(ResponsiveContext);
  const horizontalPadding = SizingHelper.horizontalPadding(size);

  return (
    <Main fill="vertical" background="white" pad={{ bottom: "80px" }}>
      <PageHeading text="Frequently Asked Questions" />
      <Box
        justify="center"
        align="center"
        background="white"
        fill="horizontal"
        pad={{ horizontal: horizontalPadding }}
      >
        <Box pad={{ horizontal: "medium" }} fill="horizontal">
          <SectionHeading text="For Families" />
          <Accordion multiple>
            <AccordionSection
              label="Why is there a need for Hey Merry in this crowded market?"
            >
              <AccordionParagraph>We have gone through many rounds of hiring helpers for our own multi-generational family over the years (whenever one of them retired), and we do not understand why there is not a single or few agencies that would represent most of the highly qualified helpers out there to make the search process highly efficient.</AccordionParagraph>
              <AccordionParagraph>As working professionals, whenever we wanted to hire a top talent, we go to the few top headhunting firms or the top schools as we know the caliber of the candidates would be among the very best, and almost all the top candidates would be found there hence making the search process very efficient.</AccordionParagraph>
              <AccordionParagraph>And there are many families out there, who appreciate the sacrifices of our helpers, and would offer them a pleasant working environment by treating them like a family and also pay them higher salaries so they can better provide for their families. Surely, helpers would like to work for such families right, and hence, we want to bring them together to know each other and find a match efficiently.</AccordionParagraph>
            </AccordionSection>
            <AccordionSection
              label="Why do you only represent helpers looking for a minimum salary of $800?"
            >
              <AccordionParagraph>Many families and we agree that helpers, especially those with excellent attitude and aptitude, are not paid sufficiently, and a salary of $800 is just a small step in helping to improve their livelihood in accordance to UN SDGs Goal #10. You can read more about it <Anchor href="https://sdgs.un.org/goals" target="_blank" label="here" />.</AccordionParagraph>
            </AccordionSection>
            <AccordionSection
              label="How can you ensure the helpers and employers you represent are good?"
            >
              <AccordionParagraph>We cannot guarantee but we trust in our judgement and experience as well as our ability to focus on what we seek to do. Our own experience in hiring helpers extensively tells us that so long we are willing to invest the time and use our judgement, we can easily identify who the better ones are, it is the effort in finding them that is daunting! And that is what we want to help you with.</AccordionParagraph>
              <AccordionParagraph>And for the employers, we strongly encourage helpers to go to their house to take a look, talk to them freely on an equal basis, and they should have a good sense of how that person and family is.</AccordionParagraph>
              <AccordionParagraph>We tell helpers not to be afraid, if the family is going to treat you as a part of them, they will talk to you like a family, so just talk and ask questions freely to get to know each other.</AccordionParagraph>
            </AccordionSection>
            <AccordionSection
              label="What if I am not satisfied with the recommended helpers?"
            >
              <AccordionParagraph>Even though you might be impressed by the helper during interview and thought she was the one you have been waiting for, you would not really know until the employment began.</AccordionParagraph>
              <AccordionParagraph>So for that purpose, we offer the options of free replacement or refund according to MOM regulations.</AccordionParagraph>
            </AccordionSection>
          </Accordion>
        </Box>
        <Box pad={{ horizontal: "medium" }} fill="horizontal">
          <SectionHeading text="For Helpers" />
          <Accordion multiple>
            <AccordionSection
              label="How are you different from other agencies?"
            >
              <AccordionParagraph>We only represent employers who want to hire a capable helper with good attitude and are willing to pay higher salaries and provide a pleasant working environment to attract such highly qualified helpers.</AccordionParagraph>
              <AccordionParagraph>So if you are qualified and are looking for good employers, we can help you to look for one efficiently.</AccordionParagraph>
            </AccordionSection>
            <AccordionSection
              label="Can I freely choose the employer I want to work for?"
            >
              <AccordionParagraph>Absolutely, we want you to choose the most suitable employer for yourself so it will work out well for everybody. Our interest is to ensure both the employer and helper are happy with each other and committed to forge a long-term relationship.</AccordionParagraph>
            </AccordionSection>
            <AccordionSection
              label="How do I start looking for employers?"
            >
              <AccordionParagraph>Simply <Anchor label="WhatsApp" href="https://wa.me/6590585214" target="_blank" /> or <Anchor label="give us a call" href="tel:65-9058-5214"/>.</AccordionParagraph>
            </AccordionSection>
          </Accordion>
        </Box>
      </Box>
    </Main>
  );
};

export default Faq;
