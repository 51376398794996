import { types } from 'mobx-state-tree';

export const Availability = types.model({
  type: types.string,
  date: types.string,
})

export const EmployerFamily = types.model({
  adults: types.maybeNull(types.string, null),
  pets: types.maybeNull(types.string, null),
  nationality: types.maybeNull(types.string, null),
  elderly: types.maybeNull(types.string, null),
  children: types.maybeNull(types.string, null),
});

export const JobScope = types.model({
  icons: types.array(types.string, []),
  description: types.maybe(types.string, null),
});

export const WorkExperience = types.model({
  comments: types.maybeNull(types.string, null),
  duration: types.maybeNull(types.string, null),
  period: types.maybeNull(types.string, null),
  housingType: types.maybeNull(types.string, null),
  reasonForLeaving: types.maybeNull(types.string, null),
  employer: types.optional(EmployerFamily, {}),
  skills: types.maybeNull(types.string, null),
});

export const Skill = types.model({
  categoryName: types.string,
  skills: types.optional(types.array(types.string), []),
});

export const Helper = types
  .model({
    id: types.identifier,
    name: types.string,
    profileImage: types.maybeNull(types.string, null),
    nationality: types.maybeNull(types.string, null),
    location: types.maybeNull(types.string, null),
    age: types.maybeNull(types.integer, null),
    maritalStatus: types.maybeNull(types.string, null),
    religion: types.maybeNull(types.string, null),
    availableOn: types.maybeNull(types.string, null),
    hireType: types.maybeNull(types.string, null),
    family: types.maybeNull(types.string, null),
    message: types.maybeNull(types.string, null),
    currentSalary: types.maybeNull(types.string, null),
    expectedSalary: types.maybeNull(types.string, null),
    yearsOfExperience: types.maybeNull(types.string, null),
    daysOffPerMonth: types.maybeNull(types.string, null),
    desiredPublicHolidayOff: types.maybeNull(types.string, null),
    languages: types.maybeNull(types.string, null),
    dietaryRestrictions: types.maybeNull(types.string, null),
    sleepingArrangement: types.maybeNull(types.string, null),
    skills: types.optional(types.map(Skill), {}),
    requests: types.maybeNull(types.string, null),
    workExperience: types.array(WorkExperience, []),
  });
