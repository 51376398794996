const camelCaseConverter = (text) => {
  if (!text) {
    return null;
  }

  let strArray = text.split(' ');

  for (let index in strArray) {
    strArray[index] = strArray[index].toLowerCase();

    if (index > 0) {
      strArray[index] = strArray[index].charAt(0).toUpperCase() + strArray[index].slice(1);
    }
  }

  return strArray.join('');
}

const getCodeForCountry = (country) => {
  const countryCode = {
    taiwan: 'TW',
    thailand: 'TH',
    middleEast: 'AE',
    sriLanka: 'LK',
    southKorea: 'KR',
    philippines: 'PH',
    myanmar: 'MM',
    malaysia: 'MY',
    macau: 'MO',
    indonesia: 'ID',
    hongKong: 'HK',
    cambodia: 'KH',
    bangladesh: 'BD',
    singapore: 'SG',
  };

  return countryCode[camelCaseConverter(country)];
}

const getCodeForNationality = (nationality) => {
  const countryCode = {
    taiwanese: 'TW',
    thai: 'TH',
    middleEastern: 'AE',
    sriLankan: 'LK',
    southKorean: 'KR',
    filipino: 'PH',
    burmese: 'MM',
    malaysian: 'MY',
    macau: 'MO',
    indonesian: 'ID',
    hongKong: 'HK',
    cambodian: 'KH',
    bangladeshi: 'BD',
    singaporean: 'SG',
  };

  return countryCode[camelCaseConverter(nationality)];
}

const countryFlag = Object.freeze({
  getCodeForCountry,
  getCodeForNationality,
});

export default countryFlag;
