import React from "react";
import { Grommet } from 'grommet';
import { BrowserRouter, Outlet, Navigate, Route, Routes } from "react-router-dom";
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import '@fontsource/roboto';
import ScrollToTop from "./ScrollToTop";
import NavHeader from './NavHeader';
import Footer from './Footer';
import WhatsAppButton from './WhatsAppButton'
import Home from './Home';
import AboutUs from "./AboutUs";
import Helpers from "./Helpers";
import Families from "./Families";
import FAQ from "./FAQ";
import Sustainability from "./Sustainability";
import ContactUs from "./ContactUs";
import HelperRecommendations from "./HelperRecommendations";
import HelperProfile from "./HelperProfile";

const theme = {
  global: {
    font: {
      family: 'KansasNew',
      size: '18px',
      height: '20px',
    },
    colors: {
      brand: '#0E3D45',
      forest: '#0E3D45',
      sage: '#E9EFE5',
      salmon: '#FFF0E7',
      siren: '#F27345',
      teal: '#4DC0AF',
      text: '#225861',
      greengrey: '#F8F9F8',
      apricot: '#FFF0E7',
      cream: '#FBF7EE',
      darkGreen: '#004150',
      lightGreen: '#D7E6B9',
    },
  },
  accordion:{
    border: {
      color: 'sage',
      side: 'bottom',
      size: 'small',
    },
    icons: {
      color: 'teal',
    },
  },
};

const App = inject('store')(observer((props) => {
  return (
    <Grommet theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <NavHeader />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/helpers" element={<Helpers />} />
          <Route path="/families" element={<Families />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/recommendations" element={<Outlet />}>
            <Route path="" element={<Navigate to="/" replace />} />
            <Route path=":recommendation_id" element={<HelperRecommendations />} />
            <Route path="profile" element={<HelperProfile />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <WhatsAppButton />
        <Footer />
      </BrowserRouter>
    </Grommet>
  );
}));

export default App;
