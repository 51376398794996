import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Header, Anchor, Box, Image, ResponsiveContext, Menu } from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';
import styled from 'styled-components';
import LogoWhite from 'images/logo_white.png'

const StyledLogoAnchor = styled(Anchor)`
  padding: 0px;
`;

const NavAnchor = ({ label, route }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCurrentLocation = pathname === route;

  return (
    <Anchor
      color={ isCurrentLocation ? "teal" : "white" }
      label={label}
      size="20px"
      weight={ isCurrentLocation ? "600" : "400" }
      onClick={() => { navigate(route) }}
    />
  );
};

const NavHeader = () => {
  const navigate = useNavigate();

  return (
    <ResponsiveContext.Consumer>
      {size =>
        size === 'small' ? (
          <Header background="forest" pad={{ vertical: 'small', left: 'small', right: 'medium' }}>
            <Anchor
              icon={
                <Box height="xxsmall" width="xsmall">
                  <Image fit="contain" src={LogoWhite} />
                </Box>
              }
              onClick={() => { navigate('/') }}
            />
            <Box justify="end">
              <Menu
                a11yTitle="Navigation Menu"
                dropProps={{ align: { top: 'bottom', right: 'right' } }}
                dropBackground="sage"
                icon={<MenuIcon color="teal" />}
                items={[
                  {
                    label: <Box pad="small">Home</Box>,
                    onClick: () => { navigate('/') }
                  },
                  {
                    label: <Box pad="small">About Us</Box>,
                    onClick: () => { navigate('/about-us') }
                  },
                  {
                    label: <Box pad="small">Helpers</Box>,
                    onClick: () => { navigate('/helpers') }
                  },
                  {
                    label: <Box pad="small">Families</Box>,
                    onClick: () => { navigate('/families') }
                  },
                  {
                    label: <Box pad="small">Sustainability</Box>,
                    onClick: () => { navigate('/sustainability') },
                  },
                  {
                    label: <Box pad="small">FAQ</Box>,
                    onClick: () => { navigate('/faq') },
                  },
                  {
                    label: <Box pad="small">Contact Us</Box>,
                    onClick: () => { navigate('/contact-us') },
                  },
                ]}
              />
            </Box>
          </Header>
        ) : (
          <Header background="forest" pad={{ left: '40', right: '80px', vertical: '28px' }}>
            <StyledLogoAnchor
              icon={
                <Box height="64px" width="small" margin="none">
                  <Image fit="contain" src={LogoWhite} />
                </Box>
              }
              onClick={() => { navigate('/') }}
            />
            <Box justify="end" direction="row" gap="medium">
              <NavAnchor label="About Us" route="/about-us" />
              <NavAnchor label="Helpers" route="/helpers" />
              <NavAnchor label="Families" route="/families" />
              <NavAnchor label="Sustainability" route="/sustainability" />
              <NavAnchor label="FAQ" route="/faq" />
              <NavAnchor label="Contact Us" route="/contact-us" />
            </Box>
          </Header>
        )
      }
    </ResponsiveContext.Consumer>
  );
}

export default NavHeader;
