import React from "react";
import { Button as GButton } from "grommet";
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const StyledButton = styled(GButton)`
  border-radius: 40px;
  width: 60px;
  height: 60px;
  text-align: center;
  bottom: 2rem;
  right: 2rem;
  position: fixed;
  color: white;
  background-color: #25d366;
  padding: 0;
`;

const WhatsAppButton = () => {
  return (
    <StyledButton
      color="#25d366"
      label={<FontAwesomeIcon icon={faWhatsapp} size="2x"/>}
      onClick={() => { window.open('https://wa.me/6590585214', '_blank', 'noopener,noreferrer');  }}
    />
  );
};

export default WhatsAppButton;
