import React, { useContext } from "react";
import { Box, Grid, Image, Main, Text, ResponsiveContext } from "grommet";
import styled from "styled-components";
import StrictScreening from "images/strict_screening.png";
import FocusedHiring from "images/focused_hiring.png";
import Paperwork from "images/paperwork.png";
import Step1 from "images/families_step_1.png";
import Step2 from "images/families_step_2.png";
import Step3 from "images/families_step_3.png";
import SizingHelper from '../helpers/sizing';
import InfoPoint from "./InfoPoint";
import Button from "./Button";
import PageHeading, { StyledSubHeading as PageSubHeading, StyledSubHeadingSmall as PageSubHeadingSmall } from "./PageHeading";
import SectionHeading from "./SectionHeading";

const StyledServiceTextTitle = styled(Text)`
  font-family: KansasNew;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const StyledServiceText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
`;

const StyledPriceLabelText = styled(Text)`
  font-family: KansasNew;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
`;

const StyledPriceText = styled(Text)`
  font-family: KansasNew;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
`;

const StyledReplacementBox = styled(Box)`
  border-radius: 10px;
  padding: 8px;
`;

const StyledPriceBox = styled(Box)`
  border-radius: 10px;
  padding: 24px 32px;
`;

const StyledReplacementText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
`;

const StyledPointText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
`;

const StyledPointNumberText = styled(Text)`
  font-family: KansasNew;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: right;
`;

const Families = () => {
  const size = useContext(ResponsiveContext);
  const horizontalPadding = SizingHelper.horizontalPadding(size);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  const subHeadingText = screenIsMobile ? (
      <PageSubHeadingSmall color="forest" margin="none" textAlign="center">Say hello to the <br />best addition <br />to your family</PageSubHeadingSmall>
    ) : (
      <PageSubHeading color="forest" margin="none" textAlign="center">Say hello to the best addition <br />to your family</PageSubHeading>
    );

  return (
    <Main fill="vertical">
      <PageHeading
        text="For Families"
        subTextChild={subHeadingText}
      />
      <SectionHeading text="What we can do for you" />
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'small' }}
        pad={{ horizontal: horizontalPadding, bottom: '40px' }}
        gap="32px"
      >
        <InfoPoint
          imageSrc={StrictScreening}
          text="Stringent screening"
          subtext="We will only introduce to you helpers that we ourselves would want to hire. We know how to look for the best for you because we ourselves have hired extensively for our own multi-generational family with young kids and elderly parents"
        />
        <InfoPoint
          imageSrc={FocusedHiring}
          text="Focused hiring"
          subtext="We will help you to draft the job description to appeal to the highly desirable helpers. Our own experience tells us that helpers who are high in demand get to choose the employers more than the other way round"
        />
        <InfoPoint
          imageSrc={Paperwork}
          text="All the paperwork fuss"
          subtext="We will help you to look for a helper and all the paperwork required for you to employ the helper according to Singapore Ministry of Manpower (MOM) regulations"
        />
      </Grid>
      <SectionHeading
        background="apricot"
        text="Our service"
      />
      <Box fill="horizontal" background="apricot" pad={{ top: '32px', bottom: '130px', horizontal: horizontalPadding }} align="center">
        <Box width={{ max: '800px' }}>
          <StyledServiceText textAlign="center" color="text">We will help you to look for a helper and do all the paperwork for the hiring according to Singapore Ministry of Manpower (MOM) regulations</StyledServiceText>
        </Box>
      </Box>
      <SectionHeading
        text="3-easy steps to hiring a helper"
      />
      {
        screenIsMobile ? (
          <Box
            pad={{ top: '24px', bottom: '40px', horizontal: 'large' }}
            gap="40px"
            width={{ max: '784px' }}
            alignSelf="center"
          >
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">01</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step1} />
            </Box>
            <Box width={{ max: '592px' }}>
              <StyledPointText color="text">We will draft your requirements and job offer attractively to ensure it stands out amongst the sea of employers</StyledPointText>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">02</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step2} />
            </Box>
            <Box width={{ max: '592px' }}>
              <StyledPointText color="text">We will send you succinct profiles of shortlisted helpers for your consideration via easy-access weblink where you can arrange for interviews conveniently. There won't be many, taking up your time, because we conduct stringent screening</StyledPointText>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">03</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step3} />
            </Box>
            <Box width={{ max: '592px' }}>
              <StyledPointText color="text">Once you decide on a helper, we will help with the paperwork</StyledPointText>
            </Box>
          </Box>
        ) : (
          <Box
            pad={{ top: '24px', bottom: '40px', horizontal: 'large' }}
            gap="40px"
            width={{ max: '784px' }}
            alignSelf="center"
          >
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">01</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step1} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">We will draft your requirements and job offer attractively to ensure it stands out amongst the sea of employers</StyledPointText>
              </Box>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">02</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step2} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">We will send you succinct profiles of shortlisted helpers for your consideration via easy-access weblink where you can arrange for interviews conveniently. It will be a really short list, to save your time, because we conduct stringent screening</StyledPointText>
              </Box>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">03</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step3} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">Once you decide on a helper, we will help with the paperwork</StyledPointText>
              </Box>
            </Box>
          </Box>
        )
      }

      <Box pad={{ horizontal: 'large', bottom: '80px' }} align="center">
        <Button
          label="Talk to us now"
          href="https://wa.me/6590585214"
          target="_blank"
        />
      </Box>
    </Main>
  );
};

export default Families;
