import React from "react";
import { render } from "react-dom";
import { Provider } from 'mobx-react';
import App from "../components";
import { RootStore } from "../models/root-store/root-store";
import { apiCalls } from "../services/api/api";

const store = RootStore.create(
  {},
  {
    // api: apiCalls,
  },
  );

document.addEventListener("DOMContentLoaded", () => {
  render(<Provider store={store}><App /></Provider>, document.body.appendChild(document.createElement("div")));
});
