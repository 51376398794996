import React, { useContext } from "react";
import { Anchor, Box, Grid, Image, Main, Text, ResponsiveContext } from "grommet";
import styled from "styled-components";
import JoinAFamily from "images/join_a_family.png";
import MinimumSalary from "images/minimum_salary.png";
import StayInTouch from "images/stay_in_touch.png";
import Step1 from "images/helpers_step_1.png";
import Step2 from "images/helpers_step_2.png";
import Step3 from "images/helpers_step_3.png";
import SizingHelper from '../helpers/sizing';
import InfoPoint, { StyledSubText as StyledInfoSubText } from "./InfoPoint";
import Button from "./Button";
import PageHeading, { StyledSubHeading as PageSubHeading, StyledSubHeadingSmall as PageSubHeadingSmall  } from "./PageHeading";
import SectionHeading from "./SectionHeading";

const StyledFeesText = styled(Text)`
  font-family: KansasNew;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const StyledFeesSubText = styled(Text)`
  font-family: KansasNew;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-style: italic;
`
const StyledPointText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
`;

const StyledPointNumberText = styled(Text)`
  font-family: KansasNew;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: right;
`;

const Helpers = () => {
  const size = useContext(ResponsiveContext);
  const horizontalPadding = SizingHelper.horizontalPadding(size);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  const subHeadingText = screenIsMobile ? (
    <PageSubHeadingSmall color="forest" margin="none" textAlign="center">Say hello to fair employment <br />& better pay</PageSubHeadingSmall>
  ) : (
    <PageSubHeading color="forest" margin="none" textAlign="center">Say hello to fair employment <br />& better pay</PageSubHeading>
  );

  return (
    <Main fill="vertical">
      <PageHeading
        text="For Helpers"
        subTextChild={subHeadingText}
      />
      <SectionHeading text="What we can do for you" />
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'small' }}
        pad={{ horizontal: horizontalPadding, bottom: '40px' }}
        gap="32px"
      >
        <InfoPoint
          imageSrc={JoinAFamily}
          text="Join a caring family"
          subtext="We will only introduce you to employers that we ourselves are comfortable working for. You are strongly encouraged to go to their house for an interview so you can see how their families are like for yourself"
        />
        <InfoPoint
          imageSrc={MinimumSalary}
          text="A minimum salary of $800"
          subtextChild={<StyledInfoSubText color="text" margin="none" textAlign="center">We will help you to understand your strengths and skills to see if any employer is willing to pay for them<br /><br />Please check out our <Anchor color="text" href="/sustainability"><b>commitment to Sustainable Development</b></Anchor> on the rationale of imposing a minimum salary, which is higher than market average</StyledInfoSubText>}
        />
        <InfoPoint
          imageSrc={StayInTouch}
          text="We will stay in touch with you"
          subtext="You can always ask us for any advice or help even after you have started work with your employer"
        />
      </Grid>
      <Box pad={{ horizontal: horizontalPadding, bottom: '40px'}} fill="horizontal" justify="center" align="center">
        <StyledFeesText textAlign="center" color="siren">We do not charge you any fees</StyledFeesText>
        <StyledFeesSubText textAlign="center" color="siren">(EVEN IF YOU ARE HIRED FROM YOUR HOME COUNTRY)</StyledFeesSubText>
      </Box>
      <SectionHeading
        text="3-easy steps to finding a caring family"
      />
      {
        screenIsMobile ? (
          <Box
            pad={{ top: '24px', bottom: '40px', horizontal: 'large' }}
            gap="40px"
            width={{ max: '784px' }}
            alignSelf="center"
          >
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">01</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step1} />
            </Box>
            <Box>
              <StyledPointText color="text">Talk to us and we will assess your qualifications, and if shortlisted, we will write up a succinct profile to introduce you to potential employers who meet your requirements</StyledPointText>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">02</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step2} />
            </Box>
            <Box>
              <StyledPointText color="text">We will arrange interviews for you with prospective employers. You are welcome to know them better and decide which family is the most suitable for you</StyledPointText>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">03</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step3} />
            </Box>
            <Box>
              <StyledPointText color="text">Once there is a match, we will take care of the paperwork for your employment </StyledPointText>
            </Box>
          </Box>
        ) : (
          <Box
            pad={{ top: '24px', bottom: '40px', horizontal: 'large' }}
            gap="40px"
            width={{ max: '784px' }}
            alignSelf="center"
          >
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">01</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step1} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">Talk to us and we will assess your qualifications, and if shortlisted, we will write up a succinct profile to introduce you to potential employers who meet your requirements</StyledPointText>
              </Box>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">02</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step2} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">We will arrange interviews for you with prospective employers. You are welcome to know them better and decide which family is the most suitable for you</StyledPointText>
              </Box>
            </Box>
            <Box direction="row" gap="30px">
              <StyledPointNumberText color="siren">03</StyledPointNumberText>
              <Image height="64px" width="64px" src={Step3} />
              <Box width={{ max: '592px' }}>
                <StyledPointText color="text">Once there is a match, we will take care of the paperwork for your employment </StyledPointText>
              </Box>
            </Box>
          </Box>
        )
      }
      <Box pad={{ horizontal: 'large', bottom: '80px' }} align="center">
        <Button
          label="Talk to us now"
          href="https://wa.me/6590585214"
          target="_blank"
        />
      </Box>
    </Main>
  );
};

export default Helpers;
