import React, { useContext } from "react";
import { Box, Carousel, Image, Main, Text, Grid, ResponsiveContext } from "grommet";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import SizingHelper from '../helpers/sizing';
import SustainableDevelopmentGoals1 from 'images/home_sustainable_development_goals_1.JPEG';
import SustainableDevelopmentGoals2 from 'images/home_sustainable_development_goals_2.JPEG';
import WhatWeDoPanel1 from 'images/what_we_do_panel_1.png';
import WhatWeDoPanel2 from 'images/what_we_do_panel_2.png';
import WhatWeDoPanel3 from 'images/what_we_do_panel_3.png';
import CreatingMerryFamilies from 'images/creating_merry_families.png';
import AsAnEmployer from 'images/as_an_employer.png';
import AsAHelper from 'images/as_a_helper.png';
import SectionHeading from "./SectionHeading";
import InfoPoint, { StyledText as StyledInfoText }from "./InfoPoint";
import Button from "./Button";

const StyledHeadingText = styled(Text)`
  font-family: KansasNew;
  font-size: 58px;
  font-weight: 400;
  line-height: 64px;
`;

const StyledHeadingTextSmall = styled(Text)`
  font-family: KansasNew;
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
`;

const StyledHeadingSubText = styled(Text)`
  font-family: freight-sans-pro;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
`;

const StyledPanelHeadingTop = styled(Text)`
  font-family: KansasNew;
  font-size: 26px;
  font-weight: 500;
  line-height: 40px;
`;

const StyledPanelHeadingBottom = styled(Text)`
  font-family: KansasNew;
  font-size: 58px;
  font-weight: 400;
  line-height: 64px;
`;

const Home = () => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  return (
    <Main fill="vertical">
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'medium' }}
      >
        <Box background="sage" pad={{ vertical: '80px', left: 'large', right: '48px' }}>
          <Box>
          {
            screenIsMobile ? (
              <>
                <StyledHeadingTextSmall>Empowering</StyledHeadingTextSmall>
                <StyledHeadingTextSmall>employers and</StyledHeadingTextSmall>
                <StyledHeadingTextSmall>giving helpers a</StyledHeadingTextSmall>
                <StyledHeadingTextSmall>reason to hope</StyledHeadingTextSmall>
              </>
            ) : (
              <>
                <StyledHeadingText>Empowering</StyledHeadingText>
                <StyledHeadingText>employers and</StyledHeadingText>
                <StyledHeadingText>giving helpers a</StyledHeadingText>
                <StyledHeadingText>reason to hope</StyledHeadingText>
              </>
            )
          }
          </Box>
          <Box margin={{ top: '24px' }}>
          {
            screenIsMobile ? (
              <StyledHeadingSubText>Addressing the pain points of employers and helpers through Hey Merry's bespoke matching service</StyledHeadingSubText>
            ) : (
              <>
                <StyledHeadingSubText>Addressing the pain points of employers and</StyledHeadingSubText>
                <StyledHeadingSubText>helpers through Hey Merry's bespoke</StyledHeadingSubText>
                <StyledHeadingSubText>matching service</StyledHeadingSubText>
              </>
            )
          }
          </Box>
        </Box>
        <Box background="sage">
          <Carousel
            controls={false}
            play={4000}
            fill
          >
            <Image fill="horizontal" fit={ screenIsMobile ? 'contain' : 'cover' } src={SustainableDevelopmentGoals1} />
            <Image fill="horizontal" fit={ screenIsMobile ? 'contain' : 'cover' } src={SustainableDevelopmentGoals2} />
          </Carousel>
        </Box>
      </Grid>
      <SectionHeading text="What we do" />
      <Box pad={
        screenIsMobile ? (
          { horizontal: 'xlarge', bottom: '40px'}
        ) : (
          { horizontal: 'large', bottom: '40px'}
        )
      }>
        <Grid
          fill="horizontal"
          columns={
            screenIsMobile ? (
              { count: 'fit', size: 'medium' }
            ) : (
              { count: 'fit', size: 'small' }
            )
          }
          pad={{ bottom: '40px' }}
          gap="32px"
          width={{ max: '1216px' }}
          alignSelf="center"
        >
          <InfoPoint
            imageSrc={WhatWeDoPanel1}
            text="Empower families in the search process by shortlisting only highly qualified helpers we ourselves want to hire"
            subtext="We look for helpers with distinctive attitudes and aptitudes and match them with families committed to offer salary progression and a pleasant work environment"
          />
          <InfoPoint
            imageSrc={WhatWeDoPanel2}
            text="Provide helpers with sustainable jobs so they can provide for their families while caring for yours"
            subtext="We take delight in assisting helpers for free to find a job that will reward them well for their outstanding effort and capabilities, so to advance their socio-economic status"
          />
          <InfoPoint
            imageSrc={WhatWeDoPanel3}
            textChild={<StyledInfoText color="forest" margin="none" textAlign="center">Contribute to the United Nations Sustainable Development Goals<br />(UN SDGs)</StyledInfoText>}
            subtext="We are wholly committed to UN SDGs, specifically to goals #8 Decent Work and #10 Reduced Inequalities as we strongly believe migrant workers deserve access to good and rewarding jobs"
          />
        </Grid>
      </Box>
      <SectionHeading text="Creating merry families" background="cream" />
      <Box
        background="cream"
        pad={
          screenIsMobile ? (
            { bottom: '80px' }
          ) : (
            { bottom: '56px' }
          )
        }
      >
        <Box width={{ max: '1008px' }} alignSelf="center">
          <Image fill="horizontal" fit="contain" src={CreatingMerryFamilies} />
        </Box>
      </Box>
      <SectionHeading text="Get on board" />
      <Grid
        columns={{ count: 'fit', size: 'medium' }}
      >
        <Box
          background="salmon"
          pad={size === 'large' || size === 'xlarge' ? { vertical: '80px', right: '115px', left: 'large' } : { vertical: '80px', horizontal: 'large' }}
          direction="row"
          justify={size === 'small' || size === 'xsmall' ? 'center' : 'end'}
        >
          <Box
            width={{ max: '486px' }}
            align="center"
          >
            <StyledPanelHeadingTop color="siren" margin={{ bottom: '4px' }}>As a caring</StyledPanelHeadingTop>
            <StyledPanelHeadingBottom color="siren" margin={{ bottom: '40px' }}>Employer</StyledPanelHeadingBottom>
            <InfoPoint
              imageSrc={AsAnEmployer}
              text="Cost efficient to employ a helper"
              subtext="Extensive search will be conducted and suitable helper profiles are presented to you via easy-access weblink where you can arrange for interviews conveniently"
            />
            <Box pad={{ top: "40px" }} fill="vertical" justify="end">
              <Button label="Read More" onClick={() => { navigate('/families') }} />
            </Box>
          </Box>
        </Box>
        <Box
          background="sage"
          pad={size === 'large' || size === 'xlarge' ? { vertical: '80px', right: 'large', left: '115px' } : { vertical: '80px', horizontal: 'large' }}
          direction="row"
          justify={size === 'small' || size === 'xsmall' ? 'center' : 'start'}
        >
          <Box
            width={{ max: '486px' }}
            align="center"
          >
            <StyledPanelHeadingTop color="text" margin={{ bottom: '4px' }}>As a sincere</StyledPanelHeadingTop>
            <StyledPanelHeadingBottom color="text" margin={{ bottom: '40px' }}>Helper</StyledPanelHeadingBottom>
            <InfoPoint
              imageSrc={AsAHelper}
              text="No fees & no salary deduction"
              subtext="Suitable families are recommended to you and you can take your time to interview and find the right family to join"
            />
            <Box pad={{ top: "40px" }} fill="vertical" justify="end">
              <Button label="Read More" onClick={() => { navigate('/helpers') }} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Main>
  );
};

export default Home;
