import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Box, Grid, Main, ResponsiveContext } from "grommet";
import { values } from "mobx";
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import PageHeading from "./PageHeading";
import HelperCard from './HelperCard';

const HelperRecommendations = inject('store')(observer(({ store, ...props }) => {
  if (store.helpers == undefined) {
    console.log('Loading screen')
  } else {
    console.log('Populate with data')
  }

  const recommendationId = useLocation().pathname.split('/').slice(-1)[0];

  useEffect(() => {
    async function fetchData() {
      await store.getRecommendations(recommendationId)
    }

    fetchData()
  }, []);

  const size = useContext(ResponsiveContext);
  const horizontalPadding = (size) => {
    if (size === 'large' || size === 'xlarge') {
      return '240px';
    } else if (size === 'medium') {
      return '60px';
    }

    return 'large';
  }
  const columns = (size) => {
    if (size == "large" || size == "xlarge") {
      return { count: 'fit', size: '1/3' }
    } else if (size == "medium") {
      return { count: 'fit', size: 'medium' }
    }
  }

  return (
    <Main fill="vertical">
      <PageHeading
        text="Our helper candidates"
        subText="Specially shortlisted for you"
      />
      <Box>
        <Grid
          fill="horizontal"
          columns={ columns(size) }
          pad={{ horizontal: horizontalPadding(size), vertical: '80px' }}
          gap={{ row: '40px', column: '32px' }}
          justify="center"
        >
        {
          store.helpers && values(store.helpers).length > 0 && (
            values(store.helpers).map(helper => {
              const {
                id,
                age,
                nationality,
                location,
                name,
                maritalStatus,
                hireType,
                availableOn,
                profileImage,
              } = helper;

              return (
                <HelperCard
                  key={id}
                  id={id}
                  age={age}
                  nationality={nationality}
                  location={location}
                  name={name}
                  maritalStatus={maritalStatus}
                  availableOn={availableOn}
                  hireType={hireType}
                  profileImage={profileImage}
                />
              );
            })
          )
        }
        {
          !store.helpers && (
            <>
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
              <HelperCard />
            </>
          )
        }
        </Grid>
      </Box>
    </Main>
  );
}));

export default HelperRecommendations;
