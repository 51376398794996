import React from "react";
import { Box, Image, Text } from "grommet";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ReactCountryFlag from "react-country-flag";
import LocationMarker from "images/location_marker.png";
import HelperAvailability from "./HelperAvailability";
import CountryFlagHelper from '../helpers/countryFlag';

export const StyledNameText = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  font-family: KansasNew;
  text-align: center;
`;

export const StyledInfoText = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  font-family: freight-sans-pro;
  text-align: center;
`;

const HelperCard = (props) => {
  const navigate = useNavigate();
  const {
    id,
    age,
    nationality,
    location,
    name,
    maritalStatus,
    hireType,
    availableOn,
    profileImage,
  } = props;
  const displayBlank = !name;
  let bioData = '';

  if (age) {
    bioData += `${age} years old`;

    if (maritalStatus) {
      bioData += ', ';
    }
  }

  if (maritalStatus) {
    bioData += maritalStatus;
  }

  return (
    <Box
      background="greengrey"
      pad={{ vertical: '32px', horizontal: '24px' }}
      width={{ max: '384px' }}
      round="10px"
      align="center"
      fill="horizontal"
      onClick={id ? () => { navigate('/recommendations/profile', { state: { id, test: 'asdf' }}) } : null}
      focusIndicator={false}
      hoverIndicator
    >
      <Box pad={{ bottom: '16px' }}>
        {
          displayBlank || !profileImage ? (
            <Box background="apricot" height="240px" width="240px" round="full" />
          ) : (
            <Box background="apricot" height="240px" width="240px" round="full" overflow="hidden">
              <Image fit="cover" src={profileImage} />
            </Box>
          )
        }
      </Box>
      <Box pad={{ bottom: '16px' }}>
        {
          displayBlank ? (
            <Box background="apricot" height="28px" width="280px" round="8px" />
          ) : (
            <StyledNameText color="forest" pad={{ bottom: '16px' }}>{name}</StyledNameText>
          )
        }
      </Box>
      <Box pad={{ bottom: '32px' }}>
        {
          displayBlank ? (
            <Box background="apricot" height="56px" width="280px" round="8px" />
          ) : (
            <Box gap="8px">
              { bioData && (
                <StyledInfoText color="text">{bioData}</StyledInfoText>
              )}
              <Box direction="row" gap="4px">
                {
                  nationality ? (
                    <Box direction="row" gap="4px">
                      <Box
                        width="24px"
                        height="24px"
                        overflow="hidden"
                        round="full"
                        justify="center"
                        align="center"
                      >
                        <ReactCountryFlag
                          countryCode={CountryFlagHelper.getCodeForNationality(nationality)}
                          svg
                          style={{
                            width: '36px',
                            height: '36px',
                          }}
                          title={nationality}
                          round="full"
                        />
                      </Box>
                      <StyledInfoText color="text">{nationality}</StyledInfoText>
                    </Box>
                  ) : (
                    <Box height="24px" />
                  )
                }
                {
                  location ? (
                    <Box direction="row" gap="4px">
                      <Image height="20px" width="20px" src={LocationMarker} />
                      <StyledInfoText color="text">{location}</StyledInfoText>
                    </Box>
                  ) : (
                    <Box height="24px" />
                  )
                }
              </Box>
            </Box>
          )
        }
      </Box>
      {
        displayBlank ? (
          <Box background="apricot" height="40px" width="180px" round="8px" />
        ) : (
          <Box
            width={{ max: '300px' }}
          >
            <HelperAvailability type={hireType} date={availableOn} />
          </Box>
        )
      }
    </Box>
  );
};

export default HelperCard;
