import React from "react";
import { Box, Image, Text } from "grommet";
import styled from 'styled-components';

export const StyledText = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  font-family: KansasNew;
`;

export const StyledSubText = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  font-family: freight-sans-pro;
`;

export const StyledBox = styled(Box)`
  min-height: initial;
`;

const InfoPoint = ({ imageSrc, text, subtext, textChild, subtextChild }) => {
  return (
    <StyledBox align="center">
      { imageSrc ? (
        <Image height="240px" src={imageSrc} />
      ) : null }
      <StyledBox margin={{ top: '24px', bottom: '16px' }}>
        <StyledText color="forest" margin="none" textAlign="center">{text}</StyledText>
        {textChild}
      </StyledBox>
      <StyledBox>
        <StyledSubText color="text" margin="none" textAlign="center">{subtext}</StyledSubText>
        {subtextChild}
      </StyledBox>
    </StyledBox>
  );
};

export default InfoPoint;
