import React, { useContext } from "react";
import { Box, Carousel, Grid, Image, Main, Paragraph as GParagraph, ResponsiveContext } from "grommet";
import styled from 'styled-components';
import SizingHelper from '../helpers/sizing';
import PageHeading, { StyledSubHeading as PageSubHeading, StyledSubHeadingSmall as PageSubHeadingSmall } from "./PageHeading";
import SectionHeading from "./SectionHeading";
import InfoPoint from "./InfoPoint";
import AboutUsSustainableDevelopmentGoals1 from "images/about_us_sustainable_development_goals_1.png";
import AboutUsSustainableDevelopmentGoals2 from "images/about_us_sustainable_development_goals_2.JPEG";
import AboutUsSustainableDevelopmentGoals3 from "images/about_us_sustainable_development_goals_3.JPEG";
import WhyHeyMerry from "images/why_hey_merry.png";

const StyledParagraph = styled(GParagraph)`
  font-family: freight-sans-pro;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
`;

const Paragraph = ({...props}) => (<StyledParagraph fill color="forest" margin="none" {...props} />)

const AboutUs = () => {
  const size = useContext(ResponsiveContext);
  const horizontalPadding = SizingHelper.horizontalPadding(size);
  const screenIsMobile = SizingHelper.screenIsMobile(size);

  const subHeadingText = screenIsMobile ? (
    <PageSubHeadingSmall color="forest" margin="none" textAlign="center">A bespoke <br />matching service <br />for families and helpers</PageSubHeadingSmall>
  ) : (
    <PageSubHeading color="forest" margin="none" textAlign="center">A bespoke matching service <br />for families and helpers</PageSubHeading>
  );

  return (
    <Main fill="vertical">
      <PageHeading text="About Us" subTextChild={subHeadingText} />
      <SectionHeading text="An efficient and pain-free search for outstanding helpers" />
      <Grid
        fill="horizontal"
        columns={{ count: 'fit', size: 'medium' }}
        pad={{ horizontal: horizontalPadding, bottom: '40px' }}
        gap="26px"
      >
        <Carousel
          controls={false}
          play={4000}
        >
          <Image fill="horizontal" fit="contain" src={AboutUsSustainableDevelopmentGoals1} />
          <Image fill="horizontal" fit="contain" src={AboutUsSustainableDevelopmentGoals2} />
          <Image fill="horizontal" fit="contain" src={AboutUsSustainableDevelopmentGoals3} />
        </Carousel>
        <Box gap="24px">
          <Paragraph>We being working professionals with vulnerable young kids and elderly parents very much wanted to find helpers who are kind, honest and capable to take care of our family.</Paragraph>
          <Paragraph>The biggest problem was that we did not know where they were, and they, the helpers, did not know how to find us either. We had to go to many agencies and go through so many profiles that left us exasperated and wondered why there is not a single agency that can attract the best helpers in Singapore to save us from all the hassles!</Paragraph>
          <Paragraph>After a time-consuming and laborious search, we finally found our lovely helpers, who are really an extension of our multi-generational family, taking great care of our kids, our elderly parents and our house. Our helpers enjoy being part of our family as we have fun together and we pay them higher salaries so they can better provide for their loved ones.</Paragraph>
          <Paragraph>Recounting on our own experiences, and the same experiences of many of our friends and people we know – both employers and helpers – we were all wishing for an agency like Hey Merry to provide us with a highly focused and efficient search process.</Paragraph>
        </Box>
      </Grid>
      <SectionHeading text="Why Hey Merry?" />
      <Box pad={{ horizontal: horizontalPadding, bottom: '80px'}}>
        <Image fill="horizontal" fit={ screenIsMobile ? "contain" : "cover" } src={WhyHeyMerry} />
        <Grid
          fill="horizontal"
          columns={{ count: 'fit', size: 'small' }}
          gap="32px"
          pad={{ bottom: '40px' }}
        >
          <InfoPoint text="Our focus" subtext="We are a boutique search agency focused on connecting only helpers with outstanding attitude and capabilities to employers who are kind and generous" />
          <InfoPoint text="Our experience" subtext="We have years of experience assessing helpers who clearly distinguish themselves from the massive pool of candidates in the market" />
          <InfoPoint text="Our network" subtext="We relentlessly develop our network of helpers and employers to sift out the best from each side and connect them with one another" />
        </Grid>
      </Box>
    </Main>
  );
};

export default AboutUs;
