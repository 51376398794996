import React from "react";
import { Box, Text } from "grommet";
import styled from 'styled-components';

export const StyledAvailabilityType = styled(Text)`
font-size: 20px;
font-weight: 600;
line-height: 23px;
font-family: freight-sans-pro;
`;

export const StyledAvailabilityDate = styled(Text)`
font-size: 20px;
font-weight: 400;
line-height: 23px;
font-family: freight-sans-pro;
`;

const HelperAvailability = ({ date, type }) => {
  const color = date ? "siren" : "teal"

  let hireType;

  if (type === 'transfer') {
    hireType = 'Transfer';
  } else if (type === 'ex_singapore') {
    hireType = 'Ex-Singapore';
  } else if (type === 'new_hire') {
    hireType = 'New';
  }

  return (
    <Box
      round="40px"
      border={{ color: color, size: 'small', style:'solid' }}
      justify="center"
      align="center"
      width={{ min: '270px', max: '350px' }}
      gap="16px"
      pad={{ vertical: '8px', horizontal: '16px' }}
      direction="row"
    >
      <StyledAvailabilityType color={color}>{hireType}</StyledAvailabilityType>
      {
        date ? (
          <Box>
            <StyledAvailabilityDate size="10px" color={color}>Available</StyledAvailabilityDate>
            <StyledAvailabilityDate size="10px" color={color}>{date}</StyledAvailabilityDate>
          </Box>
        ) : (
          <StyledAvailabilityDate size="10px" color={color}>Available Now</StyledAvailabilityDate>
        )
      }
    </Box>
  );
}

export default HelperAvailability;
